<template>
  <v-card
    id="product-card"
    class="ma-4 pa-4"
    :to="{
      name: 'ProductDetailView',
      params: { id: product.id, product: product },
    }"
  >
    <v-img height="200px" :src="require('../assets/logo.svg')" contain />
    <v-card-title class="text-h5 font-weight-medium justify-center">
      {{ product.title }}
    </v-card-title>
    <v-card-subtitle class="text-overline">
      {{ product.type }}
    </v-card-subtitle>
    <v-card-text>
      {{ product.shortDesc }}
    </v-card-text>
    <v-container>
      <v-chip class="ma-2" color="grey darken-4 white--text">
        {{ product.bitterness }} IBU
      </v-chip>
      <v-chip class="ma-2" color="yellow"> {{ product.color }} EBC </v-chip>
      <v-chip class="ma-2" color="grey darken-4 white--text">
        {{ product.alcohol }}%vol
      </v-chip>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    product: Object,
  },
};
</script>

<style>
#product-card {
  transition: transform 0.25s;
}

#product-card:hover {
  transform: scale(1.025);
}

#product-text {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 45px;
}
</style>
