<template>
  <v-container fluid pa-12 grey darken-4 white--text>
    <v-row class="text-h3 font-weight-medium text-right">
      Our Products
    </v-row>
    <v-row class="px-8 py-4" justify="center">
      <v-col
        v-for="product in items"
        :key="product.id"
        xl="4"
        lg="4"
        md="6"
        sm="12"
        xs="12"
      >
        <ProductItem :product="product" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductItem from "../components/ProductItem.vue";
import axios from "axios";

export default {
  components: {
    ProductItem,
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    axios
      .get("https://apizep.herokuapp.com/products/productItems")
      .then((response) => {
        this.items = response.data;
      });
  },
};
</script>

<style></style>
